<template>
  <modal :is-active="activeModal" :exit-modal="exitModal" :width="450">
    <div class="p-2">
      <ValidationObserver ref="observer">
        <div class="grid gap-y-5 xl:gap-x-5 grid-cols-1 xl:grid-cols-2 mb-5">
          <div>
            <label for="prenom" class="font-EffraR">Prénom</label>
            <ValidationProvider name="Prénom" rules="required" id="prenom">
              <div slot-scope="{ errors }">
                <input
                  v-model="data.firstName"
                  type="text"
                  class="
                    px-3
                    text-gray-800
                    transition-colors
                    duration-150
                    bg-white
                    border-2 border-solid
                    rounded-cu
                    w-full
                    focus:outline-none
                    focus:border-dokBlue-ultra
                    h-12
                    flex
                    items-center
                    justify-between
                    cursor-pointer
                  "
                  :class="errors[0] ? 'border-red-600' : 'border-grayaaa'"
                  placeholder="Prénom"
                />
              </div>
            </ValidationProvider>
          </div>
          <div>
            <label for="nom" class="font-EffraR">Nom</label>
            <ValidationProvider name="Nom" rules="required" id="nom">
              <div slot-scope="{ errors }">
                <input
                  v-model="data.lastName"
                  type="text"
                  class="
                    px-3
                    text-gray-800
                    transition-colors
                    duration-150
                    bg-white
                    border-2 border-solid
                    rounded-cu
                    w-full
                    focus:outline-none
                    focus:border-dokBlue-ultra
                    h-12
                    flex
                    items-center
                    justify-between
                    cursor-pointer
                  "
                  :class="errors[0] ? 'border-red-600' : 'border-grayaaa'"
                  placeholder="Nom"
                />
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="grid gap-y-5 grid-cols-1 mb-5">
          <birth-day
            :date-save="data.birthday"
            :on-set-date="setBirthDay"
            custom-class="appearance-none px-3 text-gray-800 transition-colors duration-150 bg-white border-2 border-solid border-grayaaa rounded-cu w-full focus:outline-none focus:border-dokBlue-ultra h-12 cursor-pointer"
          ></birth-day>
        </div>
        <div class="grid gap-y-5 grid-cols-1 mb-5">
          <div>
            <label for="CIN" class="font-EffraR">CIN</label>
            <input
              v-model="data.cin"
              type="text"
              class="
                    px-3
                    text-gray-800
                    transition-colors
                    duration-150
                    bg-white
                    border-2 border-solid
                    rounded-cu
                    w-full
                    focus:outline-none
                    focus:border-dokBlue-ultra
                    h-12
                    flex
                    items-center
                    justify-between
                    cursor-pointer
                    border-grayaaa
                  "
              placeholder="Numéro de la carte d'identité "
            />
          </div>
          <div class="flex flex-col">
            <label for="phone" class="font-EffraR">Téléphone</label>
            <ValidationProvider name="mobile" rules="required" id="mobile">
              <div class="relative flex flex-row" slot-scope="{ errors }">
                <div
                  class="bg-transparent text-black font-EffraR flex items-center justify-center px-2 rounded-l-cu border-2 border-solid"
                  :class="
                    phoneError || errors[0]
                      ? 'border-red-600'
                      : 'border-grayaaa'
                  "
                >
                  +212
                </div>
                <input
                  v-model="data.mobile"
                  type="number"
                  class="
                   w-full
                   placeholder-black
                   font-EffraR
                   py-4 px-1
                   border-2 border-l-0 border-solid
                  "
                  :class="
                    phoneError || errors[0]
                      ? 'border-red-600'
                      : 'border-grayaaa'
                  "
                  placeholder="ex: +212 6 67 39 05 34"
                />
              </div>
            </ValidationProvider>
            <span class="text-red-600 font-EffraR text-base" v-if="phoneError"
              >Le numéro de téléphone est incorrect</span
            >
          </div>
          <div>
            <label for="email" class="font-EffraR">Adresse mail</label>
            <ValidationProvider name="Email" rules="email" id="email">
              <div slot-scope="{ errors }">
                <input
                  v-model="data.email"
                  type="email"
                  class="
                    px-3
                    text-gray-800
                    transition-colors
                    duration-150
                    bg-white
                    border-2 
                    border-solid
                    rounded-cu
                    w-full
                    focus:outline-none
                    h-12
                    flex
                    items-center
                    justify-between
                    cursor-pointer
                  "
                  :class="
                    errors[0]
                      ? 'border-red-600'
                      : 'border-grayaaa focus:border-dokBlue-ultra'
                  "
                  placeholder="Adresse mail"
                />
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="flex flex-col xl:flex-row">
          <button
            @click="onSave"
            type="submit"
            class="
            h-12
            flex
            items-center
            justify-center
            gap-x-2
            bg-dokBlue-ultra
            rounded-cu
            border-0
            w-full
            cursor-pointer
          "
          >
            <img src="/svg/user-plus-white.svg" />
            <span class="text-lg font-EffraR text-white"
              >Créer le nouveau patient</span
            >
          </button>
        </div>
      </ValidationObserver>
    </div>
  </modal>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";
const modal = () => import("@/views/global-components/dragModal.vue");
const birthDay = () => import("@/views/global-components/birthday");
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

export default {
  props: {
    activeModal: Boolean,
    exitModal: Function
  },
  components: { modal, birthDay },
  data() {
    return {
      data: {
        role: "PATIENT"
      },
      phoneError: false
    };
  },
  methods: {
    ...mapActions("doctor", ["CREATE_PATIENT"]),
    setBirthDay(date) {
      this.data.birthday = date;
    },
    async onSave() {
      event.preventDefault();

      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        this.$forceUpdate();
        return;
      }

      if (!this.data.mobile) {
        this.phoneError = true;
        return;
      }
      const number = phoneUtil.parseAndKeepRawInput(this.data.mobile, "MA");

      if (!phoneUtil.isValidNumber(number)) {
        this.phoneError = true;
        return;
      }

      this.phoneError = false;

      /***********************  After validation Phone Number ***************************/
      this.$vs.loading();
      const { data } = await this.CREATE_PATIENT({ ...this.data });

      if (data.ok) {
        EventBus.$emit("on_success_add_new_patient", data.data);
        this.$vs.loading.close();
      }

      if (!data.ok) {
        this.$vs.loading.close();
      }

      EventBus.$emit("flashDok", {
        type: data.ok ? "success" : "errorr",
        message: data.ok ? "Patient créé avec succès" : data.message,
        timerAlert: 4000
      });
    }
  }
};
</script>
